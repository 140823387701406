/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import { IAdminAccount } from "../../interfaces";

import UserContext from "../../contexts/UserContext";

import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
  Text,
  Avatar,
  HStack,
  FormHelperText,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";
import { FaArrowCircleLeft } from "react-icons/fa";

import MainLayout from "../../components/Layout/MainLayout";

import {
  fetchAdminAccounts,
  updateAdminAccount,
} from "../../api/adminEndpoints";

interface UpdateAdminPageProps {}

interface IFormInputs {
  admin_full_name: string;
  email: string;
  password: string;
  phone_number: string;
}

const UpdateAdminPage: FC<UpdateAdminPageProps> = () => {
  const navigate = useNavigate();
  let toast = useToast();

  const admin_id = useParams().a_id;
  if (!admin_id) {
    navigate("/admins");
  }

  const { token } = useContext(UserContext);

  const [adminAccount, setAdminAccount] = useState<IAdminAccount>();

  const { register, handleSubmit, reset } = useForm<IFormInputs>({
    reValidateMode: "onChange",
    defaultValues: {
      admin_full_name: adminAccount?.admin_full_name,
      email: adminAccount?.email,
      password: "",
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAdminAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          let account = response.data.admin_accounts.filter(
            (a) => a.admin_id === parseInt(admin_id || "")
          )[0];

          if (account) {
            setAdminAccount(account);
            reset();
          } else {
            navigate("/admins");
          }
        } else {
          navigate("/admins");
        }
      })
      .catch(console.log);
  }, [token, admin_id]);

  const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
    toast.closeAll();
    const loadingInterval = setInterval(() => {
      setLoading(true);
    }, 500);

    updateAdminAccount({
      token: token,
      admin_id: parseInt(admin_id || ""),
      ...formData,
    })
      .then((response) => {
        if (response.data.status === "success") {
          navigate("/admins");

          toast({
            title:
              adminAccount?.admin_full_name + "'s account has been updated!",
            position: "bottom-right",
            status: "success",
            isClosable: true,
          });
        } else {
          toast({
            title: "Edit Admin Account Error:",
            description: response.data.errorMessage,
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        }

        setLoading(false);
        clearInterval(loadingInterval);
      })
      .catch(console.log);
  };

  return (
    <MainLayout>
      <Heading mb={"4"}>Edit Admin Account</Heading>

      <Button
        variant={"link"}
        colorScheme="black"
        leftIcon={<FaArrowCircleLeft />}
        onClick={() => {
          navigate("/admins");
        }}
      >
        back to Admin Manager
      </Button>

      <Center mt={"10"}>
        <Box
          w={{ base: "full", md: "450px" }}
          p={"8"}
          bg={"white"}
          shadow={"lg"}
          rounded={"8"}
        >
          <Center>
            <HStack mb={"8"} spacing={"4"}>
              <Avatar
                name={adminAccount?.admin_full_name}
                src="#"
                bg="gray.700"
                color={"white"}
              />
              <Box>
                <Text fontWeight={"semibold"}>
                  {adminAccount?.admin_full_name}
                </Text>
                <Text fontSize={"sm"}>{adminAccount?.email}</Text>
              </Box>
            </HStack>
          </Center>

          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={"5"}>
              <FormControl>
                <FormLabel>Full Name</FormLabel>
                <Input
                  type="text"
                  {...register("admin_full_name", {
                    required: "Full Name is required",
                  })}
                  defaultValue={adminAccount?.admin_full_name}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  {...register("email", { required: "Email is required" })}
                  defaultValue={adminAccount?.email}
                />
              </FormControl>

              <Divider />

              <Alert status="warning">
                <AlertIcon />
                Changing the password will cause the user to be Logged Out.
              </Alert>

              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input type="password" {...register("password")} />
                <FormHelperText>
                  Leave this field empty if you don't want to change it.
                </FormHelperText>
              </FormControl>
            </VStack>

            <Box mt={"10"} textAlign={"right"}>
              <Button
                colorScheme="yellow"
                mr={3}
                type={"submit"}
                isLoading={loading}
              >
                Save Changes
              </Button>
              <Button
                onClick={() => {
                  navigate("/admins");
                }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Center>
    </MainLayout>
  );
};

export default UpdateAdminPage;
