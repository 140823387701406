import { FC, useContext, useRef } from "react";
import { Link } from "react-router-dom";

import UserContext from "../../contexts/UserContext";

import {
  Box,
  VStack,
  Center,
  Avatar,
  Button,
  Icon,
  Tooltip,
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";

import {
  MdDashboard,
  MdSupervisorAccount,
  MdAttachMoney,
  MdOutlineYoutubeSearchedFor,
  MdSettings,
} from "react-icons/md";

import { RiMapPinUserFill } from "react-icons/ri";
import { BiLogOutCircle } from "react-icons/bi";

interface NavigationBarProps {}

const NavigationBar: FC<NavigationBarProps> = () => {
  const { user, setToken } = useContext(UserContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    setToken("");
    localStorage.removeItem("token");
  };

  return (
    <Box
      w={"80px"}
      bg={"gray.800"}
      borderLeft={"4px"}
      borderColor={"yellow"}
      shadow={"lg"}
      overflowY={"scroll"}
    >
      <Center py={"8"}>
        <VStack spacing={"8"}>
          <Avatar
            mb={"10"}
            name={user.admin_full_name}
            src="#"
            bg="yellow"
            color={"black"}
            fontWeight={"semibold"}
          />

          <Tooltip label="Dashboard" placement="right">
            <Link to="/">
              <Icon
                as={MdDashboard}
                w={8}
                h={8}
                color="white"
                _hover={{ cursor: "pointer", color: "yellow" }}
              />
            </Link>
          </Tooltip>

          <Tooltip label="Business Account Manager" placement="right">
            <Link to="/accounts">
              <Icon
                as={RiMapPinUserFill}
                w={8}
                h={8}
                color="white"
                _hover={{ cursor: "pointer", color: "yellow" }}
              />
            </Link>
          </Tooltip>

          <Tooltip label="Affiliate Account Manager" placement="right">
            <Link to="/affiliates">
              <Icon
                as={MdSupervisorAccount}
                w={8}
                h={8}
                color="white"
                _hover={{ cursor: "pointer", color: "yellow" }}
              />
            </Link>
          </Tooltip>

          <Tooltip label="Payments" placement="right">
            <Link to="/payments">
              <Icon
                as={MdAttachMoney}
                w={8}
                h={8}
                color="white"
                _hover={{ cursor: "pointer", color: "yellow" }}
              />
            </Link>
          </Tooltip>

          <Tooltip label="Search History" placement="right">
            <Link to="/search-history">
              <Icon
                as={MdOutlineYoutubeSearchedFor}
                w={8}
                h={8}
                color="white"
                _hover={{ cursor: "pointer", color: "yellow" }}
              />
            </Link>
          </Tooltip>

          <Tooltip label="Settings" placement="right">
            <Link to="/admins">
              <Icon
                as={MdSettings}
                w={8}
                h={8}
                color="white"
                _hover={{ cursor: "pointer", color: "yellow" }}
              />
            </Link>
          </Tooltip>

          <Divider />

          <Button variant={"link"} onClick={onOpen}>
            <Icon
              as={BiLogOutCircle}
              w={8}
              h={8}
              color="white"
              _hover={{ cursor: "pointer", color: "yellow" }}
            />
          </Button>

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader
                  fontSize="lg"
                  fontWeight="bold"
                  textAlign={"center"}
                >
                  Are you sure you want to logout?
                </AlertDialogHeader>

                <AlertDialogFooter justifyContent={"center"}>
                  <Button colorScheme="red" onClick={handleLogout} mr={3}>
                    Logout
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </VStack>
      </Center>
    </Box>
  );
};

export default NavigationBar;
