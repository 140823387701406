/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import UserContext from "../../contexts/UserContext";

import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { FaArrowCircleLeft } from "react-icons/fa";

import MainLayout from "../../components/Layout/MainLayout";
import DeleteAffiliateModal from "../../components/Modals/DeleteAffiliateModal";

import CreatePayoutModal from "../../components/Modals/CreatePayoutModal";
import { MdMenu } from "react-icons/md";
import { IAffiliateAccount, IListing, IPayout } from "../../interfaces";
import {
  fetchAffiliateAccounts,
  fetchAffiliateListings,
  fetchAffiliatePayouts,
} from "../../api/affiliateEndpoints";
import DeletePayoutModal from "../../components/Modals/DeletePayoutModal";
import { markAffiliateAsSeen } from "../../api/dashboardEndpoints";

interface AfilliatePageProps {}

const AfilliatePage: FC<AfilliatePageProps> = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const affiliate_id = useParams().id;
  if (!affiliate_id) {
    navigate("/affiliates");
  }

  const { token } = useContext(UserContext);

  const [affiliateAccount, setAffiliateAccount] = useState<IAffiliateAccount>();
  const [listings, setListings] = useState<IListing[]>();
  const [payouts, setPayouts] = useState<IPayout[]>();

  const [showDeletePayoutModal, setShowDeletePayoutModal] = useState(false);
  const [payoutToDelete, setPayoutToDelete] = useState<IPayout>();

  useEffect(() => {
    markAffiliateAsSeen({
      token: token,
      affiliate_id: parseInt(affiliate_id || ""),
    });

    fetchAffiliateAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          let account = response.data.affiliate_accounts.filter(
            (a) => a.affiliate_id === parseInt(affiliate_id || "")
          )[0];

          if (account) {
            setAffiliateAccount(account);
          } else {
            navigate("/accounts");
          }
        } else {
          navigate("/accounts");
        }
      })
      .catch(console.log);

    fetchAffiliateListings({
      token: token,
      affiliate_id: parseInt(affiliate_id || ""),
    })
      .then((response) => {
        if (response.data.status === "success") {
          setListings(response.data.affiliate_listings);
        } else {
          navigate("/accounts");
        }
      })
      .catch(console.log);

    fetchAffiliatePayouts({
      token: token,
      affiliate_id: parseInt(affiliate_id || ""),
    })
      .then((response) => {
        if (response.data.status === "success") {
          setPayouts(response.data.payouts);
        } else {
          navigate("/accounts");
        }
      })
      .catch(console.log);
  }, [token, affiliate_id]);

  const handleRefreshPayouts = () => {
    fetchAffiliatePayouts({
      token: token,
      affiliate_id: parseInt(affiliate_id || ""),
    })
      .then((response) => {
        if (response.data.status === "success") {
          setPayouts(response.data.payouts);
        } else {
          navigate("/accounts");
        }
      })
      .catch(console.log);
  };

  return (
    <MainLayout>
      <Heading mb={"4"}>View Affiliate Account</Heading>

      <Button
        variant={"link"}
        colorScheme="black"
        leftIcon={<FaArrowCircleLeft />}
        onClick={() => navigate("/affiliates")}
      >
        back to Affiliate Manager
      </Button>

      <Stack
        mt={"10"}
        direction={{ base: "column", lg: "row" }}
        align={"start"}
        spacing="24px"
      >
        <Box
          w={{ base: "full", lg: "450px" }}
          p={"8"}
          bg="white"
          shadow={"lg"}
          rounded={"8"}
        >
          <Center>
            <HStack mb={"8"} spacing={"4"}>
              <Avatar
                name={affiliateAccount?.full_name}
                src="#"
                bg="gray.700"
                color={"white"}
              />
              <Box>
                <Text fontWeight={"semibold"}>
                  {affiliateAccount?.full_name}
                </Text>
                <Text fontSize={"sm"}>{affiliateAccount?.email}</Text>
                <Text fontSize={"sm"}>{affiliateAccount?.phone_number}</Text>
              </Box>
            </HStack>
          </Center>

          <HStack
            w={"full"}
            mb={"8"}
            justify={"space-between"}
            align={"center"}
          >
            <Button
              variant={"link"}
              colorScheme="black"
              onClick={() => navigate(`/affiliates/${affiliate_id}/update`)}
            >
              Edit Account
            </Button>
            <Button
              variant={"link"}
              colorScheme="red"
              onClick={() => {
                onOpen();
              }}
            >
              Delete
            </Button>
          </HStack>

          <Divider />

          <Text mt={"8"} mb={"4"} fontWeight={"semibold"}>
            Affiliate Information:
          </Text>

          <TableContainer mb={"8"}>
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Th>Full Name</Th>
                  <Td fontSize={"sm"}>{affiliateAccount?.full_name}</Td>
                </Tr>
                <Tr>
                  <Th>Email</Th>
                  <Td fontSize={"sm"}>{affiliateAccount?.email}</Td>
                </Tr>
                <Tr>
                  <Th>Phone Number</Th>
                  <Td fontSize={"sm"}>{affiliateAccount?.phone_number}</Td>
                </Tr>
                <Tr>
                  <Th>Address</Th>
                  <Td fontSize={"sm"}>{affiliateAccount?.address}</Td>
                </Tr>
                <Tr>
                  <Th>Referral Key</Th>
                  <Td fontSize={"sm"}>{affiliateAccount?.referral_key}</Td>
                </Tr>
                <Tr>
                  <Th>Bank Name</Th>
                  <Td fontSize={"sm"}>{affiliateAccount?.bank_name}</Td>
                </Tr>
                <Tr>
                  <Th>Bank Branch Code</Th>
                  <Td fontSize={"sm"}>{affiliateAccount?.bank_branch_code}</Td>
                </Tr>
                <Tr>
                  <Th>Bank Account Holder</Th>
                  <Td fontSize={"sm"}>
                    {affiliateAccount?.bank_account_holder}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Bank Account Number</Th>
                  <Td fontSize={"sm"}>
                    {affiliateAccount?.bank_account_number}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Payout Due</Th>
                  <Td fontSize={"sm"}>R{affiliateAccount?.payout_due}</Td>
                </Tr>
                <Tr>
                  <Th>Linked Businesses</Th>
                  <Td fontSize={"sm"}>{affiliateAccount?.linked_businesses}</Td>
                </Tr>
                <Tr>
                  <Th>Date Joined</Th>
                  <Td fontSize={"sm"}>{affiliateAccount?.date_created}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <VStack w="full" spacing={"8"}>
          <Box
            w="full"
            h={"auto"}
            minH={"500px"}
            p={"8"}
            bg="white"
            shadow={"lg"}
            rounded={"8"}
          >
            <Heading size={"lg"}>Linked Businesses</Heading>

            <TableContainer mt={"10"} bg={"white"} rounded={"8"}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Business Name</Th>
                    <Th>Email</Th>
                    <Th>Date Linked</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {listings?.map((listing) => {
                    return (
                      <Tr>
                        <Td>{listing.business_name}</Td>
                        <Td>{listing.business_email}</Td>
                        <Td>{listing.date_created}</Td>
                        <Td>
                          <Button
                            variant={"link"}
                            colorScheme="black"
                            onClick={() => {
                              navigate(`/accounts/${listing.account_id}/view`);
                            }}
                          >
                            View Account
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>

          <Box
            w="full"
            h={"auto"}
            minH={"420px"}
            p={"8"}
            bg="white"
            shadow={"lg"}
            rounded={"8"}
          >
            <Stack
              w={"full"}
              justify={"space-between"}
              align={{ base: "start", md: "center" }}
              direction={{ base: "column", md: "row" }}
            >
              <Heading size={"lg"}>Payouts</Heading>

              <CreatePayoutModal
                affiliate_id={parseInt(affiliate_id || "")}
                refreshPayouts={handleRefreshPayouts}
              />
            </Stack>

            <TableContainer mt={"10"}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Amount</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {payouts?.map((payout) => {
                    return (
                      <Tr>
                        <Td>
                          R
                          {parseFloat(
                            payout.amount as unknown as string
                          ).toFixed(2)}
                        </Td>
                        <Td>{payout.date_created}</Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              icon={<MdMenu />}
                              variant="outline"
                            />
                            <MenuList>
                              <MenuItem
                                color={"red"}
                                onClick={() => {
                                  setPayoutToDelete(payout);
                                  setShowDeletePayoutModal(true);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </VStack>
      </Stack>

      <DeleteAffiliateModal
        isOpen={isOpen}
        onClose={onClose}
        accountToDelete={affiliateAccount}
      />

      <DeletePayoutModal
        isOpen={showDeletePayoutModal}
        onClose={() => setShowDeletePayoutModal(false)}
        payout={payoutToDelete}
        refreshPayouts={handleRefreshPayouts}
      />
    </MainLayout>
  );
};

export default AfilliatePage;
