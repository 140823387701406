import { FC, useCallback, useContext, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
  Text,
  useToast,
  HStack,
  Image,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { MdAddCircle } from "react-icons/md";
import { SubmitHandler, useForm } from "react-hook-form";
import UserContext from "../../contexts/UserContext";
import { createAffiliatePayout } from "../../api/affiliateEndpoints";

interface CreateServicePurchaseModalProps {
  affiliate_id: number;
  refreshPayouts: () => void;
}

interface IFormInputs {
  amount: number;
}

const CreateServicePurchaseModal: FC<CreateServicePurchaseModalProps> = ({
  affiliate_id,
  refreshPayouts,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  let toast = useToast();

  const { token } = useContext(UserContext);

  const { register, handleSubmit } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
    toast.closeAll();
    const loadingInterval = setInterval(() => {
      setLoading(true);
    }, 500);

    createAffiliatePayout({
      token: token,
      affiliate_id: affiliate_id,
      ...formData,
    })
      .then((response) => {
        if (response.data.status === "success") {
          toast({
            title: "Payout has been processed!",
            position: "bottom-right",
            status: "success",
            isClosable: true,
          });

          refreshPayouts();
          onClose();
        } else {
          toast({
            title: "Process Payout Error.",
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        }

        setLoading(false);
        clearInterval(loadingInterval);
      })
      .catch(console.log);
  };

  return (
    <Box>
      <Button
        leftIcon={<MdAddCircle />}
        colorScheme="yellow"
        variant="solid"
        onClick={onOpen}
      >
        New Payout
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "full", md: "md" }}
        isCentered
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>New Affiliate Payout</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl mt={5}>
                <FormLabel>Amount</FormLabel>
                <Input
                  type="number"
                  {...register("amount", { required: "Amount is required" })}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="yellow"
                mr={3}
                type={"submit"}
                isLoading={loading}
              >
                Process
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Box>
  );
};

export default CreateServicePurchaseModal;
