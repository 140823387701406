import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import {
  Box,
  VStack,
  Center,
  Heading,
  FormLabel,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  useToast,
} from "@chakra-ui/react";
import { FaArrowCircleLeft } from "react-icons/fa";

import { updatePassword } from "../../api/authenticationEndpoint";

interface ForgotPasswordFormFields {
  password: string;
  confirmPassword: string;
}

const UpdatePasswordPage: FC = () => {
  let navigate = useNavigate();
  let toast = useToast();

  const email = localStorage.getItem("forgot-password-email") as string;
  const one_time_pin = localStorage.getItem("forgot-password-otp") as string;
  if (!email) {
    navigate("/forgot-password");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormFields>();
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<ForgotPasswordFormFields> = (formData) => {
    toast.closeAll();
    const loadingInterval = setInterval(() => {
      setLoading(true);
    }, 500);

    if (formData.password === formData.confirmPassword) {
      updatePassword({
        email: email,
        one_time_pin: one_time_pin,
        new_password: formData.password,
      })
        .then((response) => {
          if (response.data.status === "success") {
            navigate("/");
            toast({
              title: "Password has been updated!",
              position: "top-right",
              status: "success",
              isClosable: true,
            });
          } else {
            toast({
              title: "Reset Password Error:",
              description: response.data.errorMessage,
              position: "top-right",
              status: "error",
              isClosable: true,
            });
          }

          setLoading(false);
          clearInterval(loadingInterval);
        })
        .catch(console.log);
    } else {
      toast({
        title: "Reset Password Error:",
        description: "Your passwords don't match.",
        position: "top-right",
        status: "error",
        isClosable: true,
      });

      setLoading(false);
      clearInterval(loadingInterval);
    }
  };

  return (
    <Center w={"100vw"} h={"100vh"}>
      <Button
        position={"absolute"}
        top={"10"}
        left={"10"}
        variant={"link"}
        colorScheme="black"
        leftIcon={<FaArrowCircleLeft />}
        onClick={() => navigate("/")}
      >
        Back to Login
      </Button>

      <VStack>
        <Box w={"400px"} maxW={"92vw"} p={"8"} rounded={"8"} shadow={"lg"}>
          <Heading size="lg">Reset Password</Heading>

          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack mt={"8"} spacing={"4"}>
              <FormControl isInvalid={errors.password ? true : false}>
                <FormLabel>New Password</FormLabel>
                <Input
                  type="password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.confirmPassword ? true : false}>
                <FormLabel>Confirm Password</FormLabel>
                <Input
                  type="password"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.confirmPassword?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>

            <Button
              w={"full"}
              mt={"12"}
              colorScheme="yellow"
              type={"submit"}
              isLoading={loading}
            >
              Save Password
            </Button>
          </form>
        </Box>
      </VStack>
    </Center>
  );
};

export default UpdatePasswordPage;
