import { FC } from "react";

import { Center, Heading, Text } from "@chakra-ui/react";

const PageNotFound: FC = () => {
  return (
    <Center w={"100vw"} h={"100vh"}>
      <Heading mr={"4"} size={"4xl"}>
        404
      </Heading>
      <Text size={"md"}>
        The page you are looking for does not exist or you are not logged in.
      </Text>
    </Center>
  );
};

export default PageNotFound;
