import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import {
  Box,
  VStack,
  Center,
  Heading,
  FormLabel,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  useToast,
} from "@chakra-ui/react";
import { FaArrowCircleLeft } from "react-icons/fa";

import { forgotPasswordEmail } from "../../api/authenticationEndpoint";

interface IFormInputs {
  email: string;
}

const ForgotPasswordPage: FC = () => {
  let navigate = useNavigate();
  let toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
    toast.closeAll();
    const loadingInterval = setInterval(() => {
      setLoading(true);
    }, 500);

    forgotPasswordEmail(formData)
      .then((response) => {
        if (response.data.status === "success") {
          localStorage.setItem("forgot-password-email", formData.email);
          navigate("/verify-password");
        } else {
          toast({
            title: "Forgot Password Error:",
            description: response.data.errorMessage,
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        }

        setLoading(false);
        clearInterval(loadingInterval);
      })
      .catch(console.log);
  };

  return (
    <Center w={"100vw"} h={"100vh"}>
      <Button
        position={"absolute"}
        top={"10"}
        left={"10"}
        variant={"link"}
        colorScheme="black"
        leftIcon={<FaArrowCircleLeft />}
        onClick={() => navigate("/")}
      >
        Back to Login
      </Button>

      <VStack>
        <Box w={"400px"} maxW={"92vw"} p={"8"} rounded={"8"} shadow={"lg"}>
          <Heading size="lg">Forgot Password</Heading>

          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack mt={"8"} spacing={"4"}>
              <FormControl isInvalid={errors.email ? true : false}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  {...register("email", { required: "Email is required" })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
            </VStack>

            <Button
              w={"full"}
              mt={"12"}
              colorScheme="yellow"
              type={"submit"}
              isLoading={loading}
            >
              Send Password Reset Email
            </Button>
          </form>
        </Box>
      </VStack>
    </Center>
  );
};

export default ForgotPasswordPage;
