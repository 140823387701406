import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";

import UserContext from "../../contexts/UserContext";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from "@chakra-ui/react";

import { deleteAffiliateAccount } from "../../api/affiliateEndpoints";
import { IAffiliateAccount } from "../../interfaces";

interface DeleteAffiliateModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountToDelete?: IAffiliateAccount;
}

const DeleteAffiliateModal: FC<DeleteAffiliateModalProps> = ({
  isOpen,
  onClose,
  accountToDelete,
}) => {
  let navigate = useNavigate();
  let toast = useToast();

  const { token } = useContext(UserContext);

  const handleDelete = () => {
    deleteAffiliateAccount({
      token: token,
      affiliate_id: accountToDelete?.affiliate_id || 0,
    })
      .then((response) => {
        if (response.data.status === "success") {
          navigate("/affiliates");
          onClose();

          toast({
            title: "Affiliate Account has been deleted!",
            position: "bottom-right",
            status: "success",
            isClosable: true,
          });
        }
      })
      .catch(console.log);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "full", md: "md" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Affiliate Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text>
            Are you sure you want to permanently delete{" "}
            {accountToDelete?.full_name}'s account?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteAffiliateModal;
