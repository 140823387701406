import axios from "./axios";

import { IBusinessAccount } from "../interfaces";

/*
 * Business Endpoint:
 * Fetch Business Accounts
 */

interface FetchBusinessAccountsReq {
  token: string;
}

interface FetchBusinessAccountsRes {
  data: {
    status: string;
    business_accounts: IBusinessAccount[];
  };
}

export async function fetchBusinessAccounts(
  data: FetchBusinessAccountsReq
): Promise<FetchBusinessAccountsRes> {
  return axios.post("business-accounts/fetch-business-accounts.php", data);
}

/*
 * Business Endpoint:
 * Fetch Business Listing Interactions
 */

interface FetchBusinessListingInteractionsReq {
  token: string;
  listing_id: number;
}

interface FetchBusinessListingInteractionsRes {
  data: {
    status: string;
    listing_interactions: {
      month_year: string;
      total_interactions: number;
      phone_number_clicks: number;
      email_clicks: number;
      website_clicks: number;
      location_clicks: number;
      date_string: string;
    }[];
  };
}

export async function fetchBusinessListingInteractions(
  data: FetchBusinessListingInteractionsReq
): Promise<FetchBusinessListingInteractionsRes> {
  return axios.post("listings/fetch-listing-interactions.php", data);
}

/*
 * Business Endpoint:
 * Delete Business Account
 */

interface DeleteBusinessAccountReq {
  token: string;
  account_id: number;
}

interface DeleteBusinessAccountRes {
  data: {
    status: string;
  };
}

export async function deleteBusinessAccount(
  data: DeleteBusinessAccountReq
): Promise<DeleteBusinessAccountRes> {
  return axios.post("business-accounts/delete-business-account.php", data);
}

/*
 * Business Endpoint:
 * Upload Business Card
 */

interface UploadBusinessCardRes {
  data: {
    status: string;
    business_card_link: string;
    errorMessage?: string;
  };
}

export async function uploadBusinessCard(
  data: FormData
): Promise<UploadBusinessCardRes> {
  return axios.post("listings/upload-business-card.php", data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

/*
 * Business Endpoint:
 * Update Business Account
 */

interface UpdateBusinessAccountReq {
  token: string;
  account_id: number;
  full_name: string;
  email: string;
  phone_number: string;
}

interface UpdateBusinessAccountRes {
  data: {
    status: string;
  };
}

export async function updateBusinessAccount(
  data: UpdateBusinessAccountReq
): Promise<UpdateBusinessAccountRes> {
  return axios.post("business-accounts/update-business-account.php", data);
}

/*
 * Business Endpoint:
 * Update Business Listing
 */

interface UpdateBusinessListingReq {
  token: string;
  listing_id: number;
  business_name: string;
  business_email: string;
  business_phone_number: string;
  business_website: string;
  business_address: string;
  business_whatsapp: string;
  search_keywords: string;
  business_card_link: string;
}

interface UpdateBusinessListingRes {
  data: {
    status: string;
  };
}

export async function updateBusinessListing(
  data: UpdateBusinessListingReq
): Promise<UpdateBusinessListingRes> {
  return axios.post("listings/update-listing.php", data);
}
