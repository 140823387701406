import { FC, useContext, useEffect, useState } from "react";

import UserContext from "../contexts/UserContext";

import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

import MainLayout from "../components/Layout/MainLayout";
import {
  fetchNewAffiliateAccounts,
  fetchNewBusinessAccounts,
  fetchOverview,
} from "../api/dashboardEndpoints";
import { IAffiliateAccount, IBusinessAccount } from "../interfaces";
import { Link } from "react-router-dom";

interface DashboardPageProps {}

const DashboardPage: FC<DashboardPageProps> = () => {
  const { token } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [overview, setOverview] = useState({
    business_count: 0,
    affiliate_count: 0,
    search_count: 0,
    earning_total: "",
    earning_month: "",
    payout_due: "",
  });

  const [businessAccounts, setBusinessAccounts] = useState<IBusinessAccount[]>(
    []
  );
  const [affiliateAccounts, setAffiliateAccounts] = useState<
    IAffiliateAccount[]
  >([]);

  useEffect(() => {
    fetchOverview({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setOverview(response.data.overview);
        }

        const interval = setInterval(() => {
          setLoading(false);
        }, 1000);
        return () => clearInterval(interval);
      })
      .catch(console.log);

    fetchNewBusinessAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setBusinessAccounts(response.data.business_accounts);
        }
      })
      .catch(console.log);

    fetchNewAffiliateAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setAffiliateAccounts(response.data.affiliate_accounts);
        }
      })
      .catch(console.log);
  }, [token]);

  return (
    <MainLayout>
      <Heading mb={"20"}>Dashboard</Heading>

      {loading ? (
        <Center w={"full"} h={"75%"}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="dark"
            size="xl"
          />
        </Center>
      ) : (
        <Box>
          <HStack
            mb={"8"}
            justify={"space-evenly"}
            align={"center"}
            spacing={"4"}
          >
            <Center w={"25%"} p={"5"} bg={"white"} rounded={"lg"} shadow={"lg"}>
              <VStack>
                <Text fontSize={"5xl"}>{overview.business_count}</Text>
                <Heading size={"md"}>Active Business Accounts</Heading>
              </VStack>
            </Center>

            <Center w={"25%"} p={"5"} bg={"white"} rounded={"lg"} shadow={"lg"}>
              <VStack>
                <Text fontSize={"5xl"}>{overview.affiliate_count}</Text>
                <Heading size={"md"}>Active Affiliate Accounts</Heading>
              </VStack>
            </Center>

            <Center w={"25%"} p={"5"} bg={"white"} rounded={"lg"} shadow={"lg"}>
              <VStack>
                <Text fontSize={"5xl"}>{overview.search_count}</Text>
                <Heading size={"md"}>Searches</Heading>
              </VStack>
            </Center>

            <Center w={"25%"} p={"5"} bg={"white"} rounded={"lg"} shadow={"lg"}>
              <VStack>
                <Text fontSize={"5xl"}>
                  R{parseFloat(overview.earning_total).toFixed(2)}
                </Text>
                <Heading size={"md"}>Total Earnings</Heading>
              </VStack>
            </Center>
          </HStack>

          <HStack
            mb={"14"}
            justify={"space-evenly"}
            align={"center"}
            spacing={"4"}
          >
            <Center
              w={"full"}
              p={"5"}
              bg={"white"}
              rounded={"lg"}
              shadow={"lg"}
            >
              <VStack>
                <Text fontSize={"5xl"}>
                  R{parseFloat(overview.earning_month).toFixed(2)}
                </Text>
                <Heading size={"md"}>Earnings this month</Heading>
              </VStack>
            </Center>

            <Center
              w={"full"}
              p={"5"}
              bg={"white"}
              rounded={"lg"}
              shadow={"lg"}
            >
              <VStack>
                <Text fontSize={"5xl"}>
                  R{parseFloat(overview.payout_due).toFixed(2)}
                </Text>
                <Heading size={"md"}>Total Payouts Due</Heading>
              </VStack>
            </Center>
          </HStack>

          <Box
            minH={"175px"}
            mb={"8"}
            p={"8"}
            bg="white"
            rounded={"lg"}
            shadow={"lg"}
          >
            <VStack
              w={"full"}
              h={"full"}
              justify={"space-between"}
              align={"start"}
            >
              <Heading mb={"5"} size="md" noOfLines={1}>
                ({businessAccounts.length}) New Business Accounts
              </Heading>

              {businessAccounts.length > 0 ? (
                <TableContainer w={"full"} bg={"white"} rounded={"8"}>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Business Name</Th>
                        <Th>Account Holder</Th>
                        <Th>Email</Th>
                        <Th>Date Joined</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {businessAccounts.map((account) => {
                        if (account && account.listing) {
                          return (
                            <Tr>
                              <Td>{account.listing.business_name}</Td>
                              <Td>{account.full_name}</Td>
                              <Td>{account.email}</Td>
                              <Td>{account.date_created}</Td>
                              <Td>
                                <Link
                                  to={`/accounts/${account.account_id}/view`}
                                >
                                  <Button colorScheme="yellow">
                                    View Account
                                  </Button>
                                </Link>
                              </Td>
                            </Tr>
                          );
                        }

                        return null;
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Center w={"full"} h={"full"}>
                  <Text fontSize="lg" color={"gray.500"}>
                    No new accounts at this moment
                  </Text>
                </Center>
              )}
            </VStack>
          </Box>

          <Box minH={"175px"} p={"8"} bg="white" rounded={"lg"} shadow={"lg"}>
            <VStack
              w={"full"}
              h={"full"}
              justify={"space-between"}
              align={"start"}
            >
              <Heading mb={"5"} size="md" noOfLines={1}>
                ({affiliateAccounts.length}) New Affiliate Accounts
              </Heading>

              {affiliateAccounts.length > 0 ? (
                <TableContainer w={"full"} bg={"white"} rounded={"8"}>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Full Name</Th>
                        <Th>Email</Th>
                        <Th>Phone Number</Th>
                        <Th>Date Joined</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {affiliateAccounts.map((account) => {
                        return (
                          <Tr>
                            <Td>{account.full_name}</Td>
                            <Td>{account.email}</Td>
                            <Td>{account.phone_number}</Td>
                            <Td>{account.date_created}</Td>
                            <Td>
                              <Link
                                to={`affiliates/${account.affiliate_id}/view`}
                              >
                                <Button colorScheme="yellow">
                                  View Account
                                </Button>
                              </Link>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Center w={"full"} h={"full"}>
                  <Text fontSize="lg" color={"gray.500"}>
                    No new accounts at this moment
                  </Text>
                </Center>
              )}
            </VStack>
          </Box>
        </Box>
      )}
    </MainLayout>
  );
};

export default DashboardPage;
