/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import UserContext from "../../contexts/UserContext";

import {
  Box,
  VStack,
  Center,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Input,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { FaArrowCircleLeft } from "react-icons/fa";

import MainLayout from "../../components/Layout/MainLayout";
import {
  fetchAffiliateAccounts,
  updateAffiliateAccount,
} from "../../api/affiliateEndpoints";
import { IAffiliateAccount } from "../../interfaces";

interface UpdateAffiliatePageProps {}

interface IFormInputs {
  full_name: string;
  email: string;
  phone_number: string;
  address: string;
  bank_name: string;
  bank_branch_code: string;
  bank_account_holder: string;
  bank_account_number: string;
}

const UpdateAffiliatePage: FC<UpdateAffiliatePageProps> = () => {
  const navigate = useNavigate();
  let toast = useToast();

  const affiliate_id = useParams().id;
  if (!affiliate_id) {
    navigate("/affiliates");
  }

  const { token } = useContext(UserContext);

  const [affiliateAccount, setAffiliateAccount] = useState<IAffiliateAccount>();

  const { register, handleSubmit, reset } = useForm<IFormInputs>({
    reValidateMode: "onChange",
    defaultValues: {
      full_name: affiliateAccount?.full_name,
      email: affiliateAccount?.email,
      phone_number: affiliateAccount?.phone_number,
      address: affiliateAccount?.address,
      bank_name: affiliateAccount?.bank_name,
      bank_branch_code: affiliateAccount?.bank_branch_code,
      bank_account_holder: affiliateAccount?.bank_account_holder,
      bank_account_number: affiliateAccount?.bank_account_number,
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAffiliateAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          let account = response.data.affiliate_accounts.filter(
            (a) => a.affiliate_id === parseInt(affiliate_id || "")
          )[0];

          if (account) {
            setAffiliateAccount(account);
            reset();
          } else {
            navigate("/accounts");
          }
        } else {
          navigate("/accounts");
        }
      })
      .catch(console.log);
  }, [token, affiliate_id]);

  const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
    toast.closeAll();
    const loadingInterval = setInterval(() => {
      setLoading(true);
    }, 500);

    updateAffiliateAccount({
      token: token,
      affiliate_id: parseInt(affiliate_id || ""),
      ...formData,
    })
      .then((response) => {
        if (response.data.status === "success") {
          toast({
            title: affiliateAccount?.full_name + "'s account has been updated!",
            position: "bottom-right",
            status: "success",
            isClosable: true,
          });

          navigate("/affiliates");
        } else {
          toast({
            title: "Edit Affiliate Account Error.",
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        }

        setLoading(false);
        clearInterval(loadingInterval);
      })
      .catch(console.log);
  };

  return (
    <MainLayout>
      <Heading mb={"4"}>Edit Affiliate Account</Heading>

      <Button
        variant={"link"}
        colorScheme="black"
        leftIcon={<FaArrowCircleLeft />}
        onClick={() => navigate("/affiliates")}
      >
        back to Affiliate Manager
      </Button>

      <Center mt={"10"}>
        <Box
          w={{ base: "full", md: "450px" }}
          p={"8"}
          bg={"white"}
          shadow={"lg"}
          rounded={"8"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={"5"}>
              <FormControl>
                <FormLabel>Full Name</FormLabel>
                <Input
                  type="text"
                  {...register("full_name", {
                    required: "Full Name is required",
                  })}
                  defaultValue={affiliateAccount?.full_name}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                  })}
                  defaultValue={affiliateAccount?.email}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  type="text"
                  {...register("phone_number", {
                    required: "Phone Number is required",
                  })}
                  defaultValue={affiliateAccount?.phone_number}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input
                  type="text"
                  {...register("address", {
                    required: "Address is required",
                  })}
                  defaultValue={affiliateAccount?.address}
                />
              </FormControl>

              <Divider />

              <FormControl>
                <FormLabel>Bank Name</FormLabel>
                <Input
                  type="text"
                  {...register("bank_name", {
                    required: "Bank Name is required",
                  })}
                  defaultValue={affiliateAccount?.bank_name}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Bank Branch Code</FormLabel>
                <Input
                  type="text"
                  {...register("bank_branch_code", {
                    required: "Bank Branch Code is required",
                  })}
                  defaultValue={affiliateAccount?.bank_branch_code}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Bank Account Holder</FormLabel>
                <Input
                  type="text"
                  {...register("bank_account_holder", {
                    required: "Bank Account Holder is required",
                  })}
                  defaultValue={affiliateAccount?.bank_account_holder}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Bank Account Number</FormLabel>
                <Input
                  type="text"
                  {...register("bank_account_number", {
                    required: "Bank Account Number is required",
                  })}
                  defaultValue={affiliateAccount?.bank_account_number}
                />
              </FormControl>
            </VStack>

            <Box mt={"10"} textAlign={"right"}>
              <Button
                colorScheme="yellow"
                mr={3}
                type={"submit"}
                isLoading={loading}
              >
                Save Changes
              </Button>
              <Button onClick={() => navigate("/affiliates")}>Cancel</Button>
            </Box>
          </form>
        </Box>
      </Center>
    </MainLayout>
  );
};

export default UpdateAffiliatePage;
