/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import UserContext from "../../contexts/UserContext";

import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { FaArrowCircleLeft } from "react-icons/fa";

import MainLayout from "../../components/Layout/MainLayout";
import DeleteBusinessModal from "../../components/Modals/DeleteBusinessModal";
import {
  fetchBusinessAccounts,
  fetchBusinessListingInteractions,
} from "../../api/businessEndpoints";
import { IBusinessAccount } from "../../interfaces";
import { fetchBusinessPayments } from "../../api/paymentEndpoints";
import { markBusinessAsSeen } from "../../api/dashboardEndpoints";

interface BusinessPageProps {}

interface IBusinessPayment {
  payment_id: number;
  payment_amount: number;
  date_created: string;
}

interface IInteractionLog {
  month_year: string;
  total_interactions: number;
  phone_number_clicks: number;
  email_clicks: number;
  website_clicks: number;
  location_clicks: number;
  date_string: string;
}

const BusinessPage: FC<BusinessPageProps> = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const account_id = useParams().id;
  if (!account_id) {
    navigate("/accounts");
  }

  const { token } = useContext(UserContext);

  const [businessAccount, setBusinessAccount] = useState<IBusinessAccount>();
  const [interactions, setInteractions] = useState<IInteractionLog[]>([]);
  const [payments, setPayments] = useState<IBusinessPayment[]>([]);

  useEffect(() => {
    markBusinessAsSeen({
      token: token,
      account_id: parseInt(account_id || ""),
    });

    fetchBusinessAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          let account = response.data.business_accounts.filter(
            (a) => a.account_id === parseInt(account_id || "")
          )[0];

          if (account) {
            setBusinessAccount(account);
          } else {
            navigate("/accounts");
          }
        } else {
          navigate("/accounts");
        }
      })
      .catch(console.log);

    if (businessAccount?.listing.listing_id) {
      fetchBusinessListingInteractions({
        token: token,
        listing_id: businessAccount?.listing.listing_id,
      })
        .then((response) => {
          if (response.data.status === "success") {
            setInteractions(response.data.listing_interactions);
          } else {
            navigate("/accounts");
          }
        })
        .catch(console.log);
    }

    fetchBusinessPayments({
      token: token,
      account_id: parseInt(account_id || ""),
    })
      .then((response) => {
        if (response.data.status === "success") {
          setPayments(response.data.payments);
        } else {
          navigate("/accounts");
        }
      })
      .catch(console.log);
  }, [token, account_id, businessAccount]);

  return (
    <MainLayout>
      <Heading mb={"4"}>View Business Account</Heading>

      <Button
        variant={"link"}
        colorScheme="black"
        leftIcon={<FaArrowCircleLeft />}
        onClick={() => navigate("/accounts")}
      >
        back to Business Manager
      </Button>

      <Stack
        mt={"10"}
        direction={{ base: "column", lg: "row" }}
        align={"start"}
        spacing="24px"
      >
        <Box
          w={{ base: "full", lg: "450px" }}
          p={"8"}
          bg="white"
          shadow={"lg"}
          rounded={"8"}
        >
          <VStack w={"full"} spacing={"5"} justify={"center"} align={"center"}>
            <HStack w={"full"} justify={"center"} align={"center"}>
              <Heading size={"md"} textAlign={"center"}>
                {businessAccount?.listing.business_name}
              </Heading>
            </HStack>

            <Image
              w={"full"}
              h={"200px"}
              src={businessAccount?.listing.business_card_link}
              alt="Vehicle Image"
              rounded={"8"}
              my={"4"}
            />
          </VStack>

          <HStack
            w={"full"}
            mt={"4"}
            mb={"4"}
            justify={"space-between"}
            align={"center"}
          >
            <Button
              variant={"link"}
              colorScheme="black"
              onClick={() => navigate(`/accounts/${account_id}/update`)}
            >
              Edit Account
            </Button>
            <Button
              variant={"link"}
              colorScheme="red"
              onClick={() => {
                onOpen();
              }}
            >
              Delete
            </Button>
          </HStack>

          <Divider />

          <Text mt={"8"} mb={"4"} fontWeight={"semibold"}>
            Business Information:
          </Text>

          <TableContainer mb={"8"}>
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Th>Business Name</Th>
                  <Td fontSize={"sm"}>
                    {businessAccount?.listing.business_name}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Email</Th>
                  <Td fontSize={"sm"}>
                    {businessAccount?.listing.business_email}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Phone Number</Th>
                  <Td fontSize={"sm"}>
                    {businessAccount?.listing.business_phone_number}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Website Link</Th>
                  <Td fontSize={"sm"}>
                    {businessAccount?.listing.business_website}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Address</Th>
                  <Td fontSize={"sm"}>
                    {businessAccount?.listing.business_address
                      .split(",")
                      .map((l) => {
                        return <div>{l}</div>;
                      })}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Whatsapp</Th>
                  <Td fontSize={"sm"}>
                    {businessAccount?.listing.business_whatsapp}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Location</Th>
                  <Td fontSize={"sm"}>
                    <Button size={"sm"} variant={"link"} colorScheme={"black"}>
                      <Link
                        to={`http://maps.google.com/?q=${businessAccount?.listing.business_address}`}
                        target="_blank"
                      >
                        View on Google Maps
                      </Link>
                    </Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <Text mt={"8"} mb={"4"} fontWeight={"semibold"}>
            Account Holder:
          </Text>

          <TableContainer>
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Th>Full Name</Th>
                  <Td fontSize={"sm"}>{businessAccount?.full_name}</Td>
                </Tr>
                <Tr>
                  <Th>Email</Th>
                  <Td fontSize={"sm"}>{businessAccount?.email}</Td>
                </Tr>
                <Tr>
                  <Th>Phone Number</Th>
                  <Td fontSize={"sm"}>{businessAccount?.phone_number}</Td>
                </Tr>
                <Tr>
                  <Th>Date Joined</Th>
                  <Td fontSize={"sm"}>{businessAccount?.date_created}</Td>
                </Tr>
                <Tr>
                  <Th>Linked Affiliate</Th>
                  <Td fontSize={"sm"}>
                    <Button size={"sm"} variant={"link"} colorScheme={"black"}>
                      <Link
                        to={`/affiliates/${businessAccount?.affiliate_account?.affiliate_id}/view`}
                      >
                        {businessAccount?.affiliate_account?.full_name}
                      </Link>
                    </Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <VStack w="full" spacing={"10"}>
          <Box
            w="full"
            h={"auto"}
            minH={"550px"}
            p={"8"}
            bg="white"
            shadow={"lg"}
            rounded={"8"}
          >
            <Heading size={"lg"}>Performance History</Heading>

            <TableContainer mt={"10"} bg={"white"} rounded={"8"}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Month & Year</Th>
                    <Th>Phone Number Clicks</Th>
                    <Th>Email Clicks</Th>
                    <Th>Website Clicks</Th>
                    <Th>Location Clicks</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {interactions.map((log) => {
                    return (
                      <Tr>
                        <Td>{log.date_string}</Td>
                        <Td>{log.phone_number_clicks}</Td>
                        <Td>{log.email_clicks}</Td>
                        <Td>{log.website_clicks}</Td>
                        <Td>{log.location_clicks}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>

          <Box
            w="full"
            h={"auto"}
            minH={"550px"}
            p={"8"}
            bg="white"
            shadow={"lg"}
            rounded={"8"}
          >
            <Heading size={"lg"}>Payments</Heading>

            <TableContainer mt={"10"}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Amount</Th>
                    <Th>Date</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {payments.map((payment) => {
                    return (
                      <Tr>
                        <Td>
                          R
                          {parseFloat(
                            payment.payment_amount as unknown as string
                          ).toFixed(2)}
                        </Td>
                        <Td>{payment.date_created}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </VStack>
      </Stack>

      <DeleteBusinessModal
        isOpen={isOpen}
        onClose={onClose}
        accountToDelete={businessAccount}
      />
    </MainLayout>
  );
};

export default BusinessPage;
