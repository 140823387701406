import axios from "./axios";

import { ISearch } from "../interfaces";

/*
 * Search Endpoint:
 * Fetch Searches
 */

interface FetchSearchHistoryReq {
  token: string;
}

interface FetchSearchHistoryRes {
  data: {
    status: string;
    searches: ISearch[];
  };
}

export async function fetchSearchHistory(
  data: FetchSearchHistoryReq
): Promise<FetchSearchHistoryRes> {
  return axios.post("search/fetch-search-logs.php", data);
}
