import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import UserContext from "../../contexts/UserContext";

import {
  Stack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Center,
  Icon,
  VStack,
  useDisclosure,
  Spinner,
  HStack,
  FormControl,
  Input,
  Box,
} from "@chakra-ui/react";

import { MdMenu } from "react-icons/md";
import { RiMapPinUserFill } from "react-icons/ri";

import MainLayout from "../../components/Layout/MainLayout";
import DeleteBusinessModal from "../../components/Modals/DeleteBusinessModal";
import { fetchBusinessAccounts } from "../../api/businessEndpoints";
import { IBusinessAccount } from "../../interfaces";

interface DriverManagerPageProps {}

const DriverManagerPage: FC<DriverManagerPageProps> = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { token } = useContext(UserContext);

  const [loading, setLoading] = useState(true);

  const [businessAccounts, setBusinessAccounts] = useState<IBusinessAccount[]>(
    []
  );

  const [search, setSearch] = useState("");

  const [accountToDelete, setAccountToDelete] = useState<IBusinessAccount>();

  useEffect(() => {
    fetchBusinessAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setBusinessAccounts(response.data.business_accounts);
        }

        const interval = setInterval(() => {
          setLoading(false);
        }, 1000);
        return () => clearInterval(interval);
      })
      .catch(console.log);
  }, [token]);

  const handleFilterSearch = (account: IBusinessAccount) => {
    if (search === "") {
      return true;
    }

    if (
      account.listing.business_name
        .toLowerCase()
        .includes(search.toLocaleLowerCase())
    ) {
      return true;
    }

    if (account.full_name.toLowerCase().includes(search.toLocaleLowerCase())) {
      return true;
    }

    if (account.email.toLowerCase().includes(search.toLocaleLowerCase())) {
      return true;
    }

    return false;
  };

  return (
    <MainLayout>
      <Stack
        w={"full"}
        mb={"20"}
        justify={"space-between"}
        align={{ base: "start", md: "center" }}
        direction={{ base: "column", md: "row" }}
      >
        <Heading>Business Account Manager</Heading>
      </Stack>

      {loading ? (
        <Center w={"full"} h={"75%"}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="dark"
            size="xl"
          />
        </Center>
      ) : (
        <Box>
          {businessAccounts.length > 0 ? (
            <Box>
              <HStack mb={"5"} justify={"space-between"} align={"end"}>
                <HStack
                  justify={"space-between"}
                  align={"center"}
                  spacing={"8"}
                >
                  <FormControl w={"275px"}>
                    <Input
                      placeholder="Search..."
                      size="md"
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </FormControl>
                </HStack>
              </HStack>

              <TableContainer bg={"white"} rounded={"8"}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Business Name</Th>
                      <Th>Account Holder</Th>
                      <Th>Email</Th>
                      <Th>Status</Th>
                      <Th>Performance</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {businessAccounts
                      .filter(handleFilterSearch)
                      .map((account) => {
                        return (
                          <Tr
                            _hover={{ cursor: "pointer", bg: "gray.100" }}
                            onClick={() =>
                              navigate(`/accounts/${account.account_id}/view`)
                            }
                          >
                            <Td>{account.listing.business_name}</Td>
                            <Td>{account.full_name}</Td>
                            <Td>{account.email}</Td>
                            <Td>{account.status}</Td>
                            <Td>{account.interactions || 0} interactions</Td>
                            <Td onClick={(e) => e.stopPropagation()}>
                              <Menu>
                                <MenuButton
                                  as={IconButton}
                                  icon={<MdMenu />}
                                  variant="outline"
                                />
                                <MenuList>
                                  <MenuItem
                                    onClick={() =>
                                      navigate(
                                        `/accounts/${account.account_id}/view`
                                      )
                                    }
                                  >
                                    Open
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      navigate(
                                        `/accounts/${account.account_id}/update`
                                      )
                                    }
                                  >
                                    Edit Account
                                  </MenuItem>
                                  <MenuDivider />
                                  <MenuItem
                                    color={"red"}
                                    onClick={() => {
                                      setAccountToDelete(account);
                                      onOpen();
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Center w={"full"} h={"75%"}>
              <VStack>
                <Icon
                  as={RiMapPinUserFill}
                  w={"20"}
                  h={"20"}
                  color="blackAlpha.800"
                />
                <Text fontSize={"lg"}>No Business Accounts Found</Text>
              </VStack>
            </Center>
          )}
        </Box>
      )}

      <DeleteBusinessModal
        isOpen={isOpen}
        onClose={onClose}
        accountToDelete={accountToDelete}
      />
    </MainLayout>
  );
};

export default DriverManagerPage;
