/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import UserContext from "./contexts/UserContext";

import { fetchUser } from "./api/authenticationEndpoint";

import LoginPage from "./pages/authentication/LoginPage";

import ForgotPasswordPage from "./pages/authentication/ForgotPasswordPage";
import VerifyPasswordPage from "./pages/authentication/VerifyPasswordPage";
import UpdatePasswordPage from "./pages/authentication/UpdatePasswordPage";

import DashboardPage from "./pages/DashboardPage";

import BusinessManagerPage from "./pages/businesses/BusinessManagerPage";
import BusinessPage from "./pages/businesses/BusinessPage";
import UpdateBusinessPage from "./pages/businesses/UpdateBusinessPage";

import AffiliateManagerPage from "./pages/affiliates/AffiliateManagerPage";
import AffiliatePage from "./pages/affiliates/AffiliatePage";
import UpdateAffiliatePage from "./pages/affiliates/UpdateAffiliatePage";

import PaymentsPage from "./pages/payments/PaymentsPage";

import SearchHistoryPage from "./pages/searches/SearchHistoryPage";

import AdminManagerPage from "./pages/admins/AdminManagerPage";
import CreateAdminPage from "./pages/admins/CreateAdminPage";
import UpdateAdminPage from "./pages/admins/UpdateAdminPage";

import SettingsPage from "./pages/admins/SettingsPage";

import PageNotFound from "./pages/PageNotFound";

import InitialLoader from "./components/InitialLoader";

const Application: FC = () => {
  const { token, setToken, setUser } = useContext(UserContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      fetchUser({ token: token })
        .then((response) => {
          if (response.data.status === "success") {
            setUser(response.data.admin_account);
          } else {
            localStorage.removeItem("token");
            setToken("");
          }
        })
        .catch(console.log);
    }

    const interval = setInterval(() => {
      setLoading(false);
    }, 2500);
    return () => clearInterval(interval);
  }, [token]);

  return loading ? (
    <InitialLoader />
  ) : (
    <Routes>
      {token ? (
        <Route>
          <Route path="/" element={<DashboardPage />} />

          <Route>
            <Route path="/accounts" element={<BusinessManagerPage />} />
            <Route path="/accounts/:id/view" element={<BusinessPage />} />
            <Route
              path="/accounts/:id/update"
              element={<UpdateBusinessPage />}
            />
          </Route>

          <Route>
            <Route path="/affiliates" element={<AffiliateManagerPage />} />
            <Route path="/affiliates/:id/view" element={<AffiliatePage />} />
            <Route
              path="/affiliates/:id/update"
              element={<UpdateAffiliatePage />}
            />
          </Route>

          <Route path="/payments" element={<PaymentsPage />} />

          <Route path="/search-history" element={<SearchHistoryPage />} />

          <Route>
            <Route path="/admins" element={<AdminManagerPage />} />
            <Route path="/admins/create" element={<CreateAdminPage />} />
            <Route path="/admins/:a_id/update" element={<UpdateAdminPage />} />
          </Route>

          <Route path="/settings" element={<SettingsPage />} />
        </Route>
      ) : (
        <Route>
          <Route path="/" element={<LoginPage />} />

          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/verify-password" element={<VerifyPasswordPage />} />
          <Route path="/update-password" element={<UpdatePasswordPage />} />
        </Route>
      )}

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Application;
