import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import {
  Box,
  VStack,
  Center,
  Heading,
  Text,
  FormLabel,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  useToast,
} from "@chakra-ui/react";
import { FaArrowCircleLeft } from "react-icons/fa";

import { forgotPasswordOTP } from "../../api/authenticationEndpoint";

interface ForgotPasswordFormFields {
  one_time_pin: string;
}

const VerifyPasswordPage: FC = () => {
  let navigate = useNavigate();
  let toast = useToast();

  const email = localStorage.getItem("forgot-password-email") as string;
  if (!email) {
    navigate("/forgot-password");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormFields>();
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<ForgotPasswordFormFields> = (formData) => {
    toast.closeAll();
    const loadingInterval = setInterval(() => {
      setLoading(true);
    }, 500);

    forgotPasswordOTP({ ...formData, email: email })
      .then((response) => {
        if (response.data.status === "success") {
          localStorage.setItem("forgot-password-otp", formData.one_time_pin);
          navigate("/update-password");
        } else {
          toast({
            title: "Verify Password Error:",
            description: response.data.errorMessage,
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        }

        setLoading(false);
        clearInterval(loadingInterval);
      })
      .catch(console.log);
  };

  return (
    <Center w={"100vw"} h={"100vh"}>
      <Button
        position={"absolute"}
        top={"10"}
        left={"10"}
        variant={"link"}
        colorScheme="black"
        leftIcon={<FaArrowCircleLeft />}
        onClick={() => navigate("/")}
      >
        Back to Login
      </Button>

      <VStack>
        <Box w={"400px"} maxW={"92vw"} p={"8"} rounded={"8"} shadow={"lg"}>
          <Heading size="lg">Email Verification</Heading>
          <Text fontSize="sm">
            Check your inbox for a unique OTP code to verify your email address.
          </Text>

          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack mt={"8"} spacing={"4"}>
              <FormControl isInvalid={errors.one_time_pin ? true : false}>
                <FormLabel>One-Time Pin</FormLabel>
                <Input
                  type="text"
                  {...register("one_time_pin", { required: "OTP is required" })}
                />
                <FormErrorMessage>
                  {errors.one_time_pin?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>

            <Button
              w={"full"}
              mt={"12"}
              colorScheme="yellow"
              type={"submit"}
              isLoading={loading}
            >
              Verify Email
            </Button>
          </form>
        </Box>
      </VStack>
    </Center>
  );
};

export default VerifyPasswordPage;
