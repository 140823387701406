import { FC, useContext, useEffect, useState } from "react";

import UserContext from "../../contexts/UserContext";

import {
  Stack,
  Avatar,
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
  FormControl,
  FormLabel,
  HStack,
  TableCaption,
  Box,
  Input,
  Center,
  Spinner,
} from "@chakra-ui/react";

import MainLayout from "../../components/Layout/MainLayout";
import { fetchSearchHistory } from "../../api/searchEndpoints";
import { ISearch } from "../../interfaces";
import { DateTime } from "luxon";

interface SearchHistoryPageProps {}

const SearchHistoryPage: FC<SearchHistoryPageProps> = () => {
  const { token } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [searchHistory, setSearchHistory] = useState<ISearch[]>([]);

  const [filterDate, setFilterDate] = useState("today");

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  useEffect(() => {
    fetchSearchHistory({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setSearchHistory(response.data.searches);
        }

        const interval = setInterval(() => {
          setLoading(false);
        }, 1000);
        return () => clearInterval(interval);
      })
      .catch(console.log);
  }, [token]);

  const handleFilterDate = (search: ISearch) => {
    const d1 = DateTime.fromISO((search.date_created || "").replace(" ", "T"));

    if (filterDate === "today") {
      const d2 = DateTime.now();

      if (d2.hasSame(d1, "day")) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "yesterday") {
      const d2 = DateTime.now().minus({ days: 1 });

      if (d2.hasSame(d1, "day")) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "this-week") {
      const d2 = DateTime.now();

      if (d1.weekNumber === d2.weekNumber && d1.year === d2.year) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "last-week") {
      const d2 = DateTime.now();

      if (d1.weekNumber === d2.weekNumber - 1 && d1.year === d2.year) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "this-month") {
      const d2 = DateTime.now();

      if (d1.month === d2.month && d1.year === d2.year) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "last-month") {
      const d2 = DateTime.now();

      if (d1.month === d2.month - 1 && d1.year === d2.year) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "this-year") {
      const d2 = DateTime.now();

      if (d1.year === d2.year) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "custom") {
      const d2 = DateTime.fromISO(dateFrom);
      const d3 = DateTime.fromISO(dateTo);

      console.log(d2, d3);

      if (d1 > d2 && d1 < d3) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <MainLayout>
      <Stack
        w={"full"}
        mb={"20"}
        justify={"space-between"}
        align={{ base: "start", md: "center" }}
        direction={{ base: "column", md: "row" }}
      >
        <Heading>Search History</Heading>
      </Stack>

      {loading ? (
        <Center w={"full"} h={"75%"}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="dark"
            size="xl"
          />
        </Center>
      ) : (
        <Box>
          <HStack mb={"5"} justify={"flex-end"} align={"end"}>
            <HStack justify={"space-between"} align={"end"} spacing={"8"}>
              {filterDate === "custom" ? (
                <HStack>
                  <FormControl w={"220px"}>
                    <FormLabel>From:</FormLabel>
                    <Input
                      placeholder="Select Date and Time"
                      size="md"
                      type="datetime-local"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                  </FormControl>

                  <FormControl w={"220px"}>
                    <FormLabel>To:</FormLabel>
                    <Input
                      placeholder="Select Date and Time"
                      size="md"
                      type="datetime-local"
                      value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                    />
                  </FormControl>
                </HStack>
              ) : null}

              <FormControl w={"150px"}>
                <Select onChange={(e) => setFilterDate(e.target.value)}>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="this-week">This Week</option>
                  <option value="last-week">Last Week</option>
                  <option value="this-month">This Month</option>
                  <option value="last-month">Last Month</option>
                  <option value="this-year">This Year</option>
                  <option value="custom">Custom</option>
                </Select>
              </FormControl>
            </HStack>
          </HStack>

          <TableContainer bg={"white"} rounded={"8"}>
            <Table variant="simple">
              <TableCaption>
                Total Searches: {searchHistory.filter(handleFilterDate).length}
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>Search Term</Th>
                  <Th>Location</Th>
                  <Th>Results</Th>
                  <Th>Date & Time</Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchHistory.filter(handleFilterDate).map((log) => {
                  return (
                    <Tr>
                      <Td>{log.search_term}</Td>
                      <Td>{log.location}</Td>
                      <Td>{log.results}</Td>
                      <Td>{log.date_created}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </MainLayout>
  );
};

export default SearchHistoryPage;
