import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";

import UserContext from "../../contexts/UserContext";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from "@chakra-ui/react";
import { IBusinessAccount } from "../../interfaces";
import { deleteBusinessAccount } from "../../api/businessEndpoints";

interface DeleteBusinessModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountToDelete?: IBusinessAccount;
}

const DeleteBusinessModal: FC<DeleteBusinessModalProps> = ({
  isOpen,
  onClose,
  accountToDelete,
}) => {
  let navigate = useNavigate();
  let toast = useToast();

  const { token } = useContext(UserContext);

  const handleDelete = () => {
    if (accountToDelete?.account_id) {
      deleteBusinessAccount({
        token: token,
        account_id: accountToDelete?.account_id,
      })
        .then((response) => {
          if (response.data.status === "success") {
            navigate("/accounts");
            onClose();

            toast({
              title: "Business Account has been deleted!",
              position: "bottom-right",
              status: "success",
              isClosable: true,
            });
          }
        })
        .catch(console.log);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "full", md: "md" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Business Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text>
            Are you sure you want to permanently delete{" "}
            {accountToDelete?.listing.business_name}'s account?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteBusinessModal;
