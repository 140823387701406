/* eslint-disable @typescript-eslint/no-redeclare */
import { FC, ReactNode, createContext, useState } from "react";

interface UserContext {
  token: string;
  setToken: (token: string) => void;
  user: {
    admin_id: number;
    admin_full_name: string;
    email: string;
  };
  setUser: (user: any) => void;
}

interface UserContextProps {
  children: ReactNode;
}

const UserContextDefaultValues: UserContext = {
  token: "",
  setToken: (token: string) => {},
  user: {
    admin_id: 0,
    admin_full_name: "",
    email: "",
  },
  setUser: (user: any) => {},
};

const UserContext = createContext<UserContext>(UserContextDefaultValues);

const UserProvider: FC<UserContextProps> = ({ children }) => {
  const [token, setToken] = useState(
    (localStorage.getItem("token") as string) || ""
  );

  const [user, setUser] = useState({
    admin_id: 0,
    admin_full_name: "",
    email: "",
  });

  console.log(token, user);

  return (
    <UserContext.Provider value={{ token, setToken, user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

export { UserProvider };
