/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";

import UserContext from "../../contexts/UserContext";

import {
  Box,
  VStack,
  Center,
  Heading,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Divider,
  Icon,
  Spinner,
  Image,
  useToast,
} from "@chakra-ui/react";
import { MdFileUpload } from "react-icons/md";
import { FaArrowCircleLeft } from "react-icons/fa";

import MainLayout from "../../components/Layout/MainLayout";
import { IBusinessAccount } from "../../interfaces";
import {
  fetchBusinessAccounts,
  updateBusinessAccount,
  updateBusinessListing,
  uploadBusinessCard,
} from "../../api/businessEndpoints";

interface UpdateDriverPageProps {}

interface IFormInputs {
  business_name: string;
  business_email: string;
  business_phone_number: string;
  business_website: string;
  business_address: string;
  business_whatsapp: string;
  search_keywords: string;
  full_name: string;
  email: string;
  phone_number: string;
}

const UpdateDriverPage: FC<UpdateDriverPageProps> = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const account_id = useParams().id;
  if (!account_id) {
    navigate("/accounts");
  }

  const { token } = useContext(UserContext);

  const [businessAccount, setBusinessAccount] = useState<IBusinessAccount>();

  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadLink, setUploadLink] = useState(
    businessAccount?.listing.business_card_link || ""
  );

  const { register, handleSubmit, reset } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBusinessAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          let account = response.data.business_accounts.filter(
            (a) => a.account_id === parseInt(account_id || "")
          )[0];

          if (account) {
            setBusinessAccount(account);
            reset();
            setUploadLink(account.listing.business_card_link);
          } else {
            navigate("/accounts");
          }
        } else {
          navigate("/accounts");
        }
      })
      .catch(console.log);
  }, [token, account_id]);

  const onDrop = useCallback((acceptedFiles: any) => {
    setUploadLoading(true);

    const formData = new FormData();

    formData.append("token", token);
    formData.append("business_card", acceptedFiles[0]);

    uploadBusinessCard(formData)
      .then((response) => {
        if (response.data.status === "success") {
          setUploadLink(response.data.business_card_link);
        } else {
          toast({
            title: "Business Card Upload Error:",
            description: response.data.errorMessage,
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        }

        setUploadLoading(false);
      })
      .catch(console.log);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
    toast.closeAll();
    const loadingInterval = setInterval(() => {
      setLoading(true);
    }, 500);

    updateBusinessAccount({
      token: token,
      account_id: businessAccount?.account_id || 0,
      ...formData,
    })
      .then((response) => {
        if (response.data.status === "success") {
          updateBusinessListing({
            token: token,
            listing_id: businessAccount?.listing.listing_id || 0,
            business_card_link: uploadLink,
            ...formData,
          })
            .then((response) => {
              if (response.data.status === "success") {
                navigate("/accounts");

                toast({
                  title:
                    businessAccount?.listing.business_name +
                    "'s account has been updated!",
                  position: "bottom-right",
                  status: "success",
                  isClosable: true,
                });
              } else {
                toast({
                  title: "Edit Business Error",
                  position: "top-right",
                  status: "error",
                  isClosable: true,
                });
              }

              setLoading(false);
              clearInterval(loadingInterval);
            })
            .catch(console.log);
        } else {
          toast({
            title: "Edit Business Error",
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        }

        setLoading(false);
        clearInterval(loadingInterval);
      })
      .catch(console.log);
  };

  return (
    <MainLayout>
      <Heading mb={"4"}>Edit Business Account</Heading>

      <Button
        variant={"link"}
        colorScheme="black"
        leftIcon={<FaArrowCircleLeft />}
        onClick={() => navigate("/accounts")}
      >
        back to Business Manager
      </Button>

      <Center mt={"10"}>
        <Box
          w={{ base: "full", md: "450px" }}
          p={"8"}
          bg={"white"}
          shadow={"lg"}
          rounded={"8"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={"5"}>
              <Heading w={"full"} pb={"5"} textAlign={"left"} size={"md"}>
                Business Information:
              </Heading>

              <FormControl>
                <FormLabel>Business Name</FormLabel>
                <Input
                  type="text"
                  {...register("business_name", {
                    required: "Business Name is required",
                  })}
                  defaultValue={businessAccount?.listing.business_name}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="text"
                  {...register("business_email", {
                    required: "Business Email is required",
                  })}
                  defaultValue={businessAccount?.listing.business_email}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  type="text"
                  {...register("business_phone_number", {
                    required: "Business Phone Number is required",
                  })}
                  defaultValue={businessAccount?.listing.business_phone_number}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Website</FormLabel>
                <Input
                  type="text"
                  {...register("business_website", {
                    required: "Business Website is required",
                  })}
                  defaultValue={businessAccount?.listing.business_website}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input
                  type="text"
                  {...register("business_address", {
                    required: "Business Address is required",
                  })}
                  defaultValue={businessAccount?.listing.business_address}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Whatsapp Number</FormLabel>
                <Input
                  type="text"
                  {...register("business_whatsapp", {
                    required: "Whatsapp Number is required",
                  })}
                  defaultValue={businessAccount?.listing.business_whatsapp}
                />
              </FormControl>

              <Divider />

              <FormControl>
                <FormLabel>Search Keywords</FormLabel>
                <Textarea
                  {...register("search_keywords", {
                    required: "At least one Search Keyword is required",
                  })}
                  defaultValue={businessAccount?.listing.search_keywords}
                />
              </FormControl>

              <Divider />

              {uploadLink.length > 0 ? (
                <VStack w={"full"} pt={"5"} justify={"space-between"}>
                  <Image
                    w={"100%"}
                    h={"auto"}
                    src={uploadLink}
                    alt="Business Card Image"
                    rounded={"8"}
                  />

                  <VStack w={"50%"} justify={"center"} align={"center"}>
                    <Text>Upload Completed!</Text>
                    <Button onClick={() => setUploadLink("")}>
                      Replace Image
                    </Button>
                  </VStack>
                </VStack>
              ) : (
                <FormControl>
                  <FormLabel>Replace Business Card</FormLabel>

                  <Box
                    w={"full"}
                    p={"10"}
                    bg={"gray.100"}
                    border={"1px"}
                    borderColor={"gray.200"}
                    rounded={"8"}
                    cursor={"pointer"}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />

                    {uploadLoading ? (
                      <Center w={"full"} h={"96px"}>
                        <Spinner
                          thickness="2px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="black"
                          size="md"
                          mr={"4"}
                        />
                        <Text>Uploading...</Text>
                      </Center>
                    ) : (
                      <VStack justify={"space-between"} align={"center"}>
                        <Icon as={MdFileUpload} w={8} h={8} color="gray.600" />
                        <Text color={"gray.500"}>Drag & Drop Here</Text>
                        <Text color={"gray.500"} textAlign={"center"}>
                          or click to Select a File From Your Computer
                        </Text>
                      </VStack>
                    )}
                  </Box>
                </FormControl>
              )}

              <Divider />

              <Heading w={"full"} pb={"5"} textAlign={"left"} size={"md"}>
                Account Holder Information:
              </Heading>

              <FormControl>
                <FormLabel>Full Name</FormLabel>
                <Input
                  type="text"
                  {...register("full_name", {
                    required: "Full Name is required",
                  })}
                  defaultValue={businessAccount?.full_name}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                  })}
                  defaultValue={businessAccount?.email}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  type="text"
                  {...register("phone_number", {
                    required: "Phone Number is required",
                  })}
                  defaultValue={businessAccount?.phone_number}
                />
              </FormControl>
            </VStack>

            <Box mt={"10"} textAlign={"right"}>
              <Button
                colorScheme="yellow"
                mr={3}
                type={"submit"}
                isLoading={loading}
              >
                Save Changes
              </Button>
              <Button onClick={() => navigate("/accounts")}>Cancel</Button>
            </Box>
          </form>
        </Box>
      </Center>
    </MainLayout>
  );
};

export default UpdateDriverPage;
