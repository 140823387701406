import axios from "./axios";

import { IAffiliateAccount, IListing, IPayout } from "../interfaces";

/*
 * Affiliate Endpoint:
 * Fetch Affiliate Accounts
 */

interface FetchAffiliateAccountsReq {
  token: string;
}

interface FetchAffiliateAccountsRes {
  data: {
    status: string;
    affiliate_accounts: IAffiliateAccount[];
  };
}

export async function fetchAffiliateAccounts(
  data: FetchAffiliateAccountsReq
): Promise<FetchAffiliateAccountsRes> {
  return axios.post("affiliate-accounts/fetch-affiliate-accounts.php", data);
}

/*
 * Affiliate Endpoint:
 * Fetch Affiliate Listings
 */

interface FetchAffiliateListingsReq {
  token: string;
  affiliate_id: number;
}

interface FetchAffiliateListingsRes {
  data: {
    status: string;
    affiliate_listings: IListing[];
  };
}

export async function fetchAffiliateListings(
  data: FetchAffiliateListingsReq
): Promise<FetchAffiliateListingsRes> {
  return axios.post("affiliate-accounts/fetch-affiliate-listings.php", data);
}

/*
 * Affiliate Endpoint:
 * Fetch Affiliate Payouts
 */

interface FetchAffiliatePayoutsReq {
  token: string;
  affiliate_id: number;
}

interface FetchAffiliatePayoutsRes {
  data: {
    status: string;
    payouts: IPayout[];
  };
}

export async function fetchAffiliatePayouts(
  data: FetchAffiliatePayoutsReq
): Promise<FetchAffiliatePayoutsRes> {
  return axios.post("affiliate-accounts/fetch-affiliate-payouts.php", data);
}

/*
 * Affiliate Endpoint:
 * Create Affiliate Payout
 */

interface CreateAffiliatePayoutReq {
  token: string;
  amount: number;
  affiliate_id: number;
}

interface CreateAffiliatePayoutRes {
  data: {
    status: string;
  };
}

export async function createAffiliatePayout(
  data: CreateAffiliatePayoutReq
): Promise<CreateAffiliatePayoutRes> {
  return axios.post("affiliate-accounts/create-payout.php", data);
}

/*
 * Affiliate Endpoint:
 * Delete Affiliate Payout
 */

interface DeleteAffiliatePayoutReq {
  token: string;
  amount: number;
  affiliate_id: number;
  payout_id: number;
}

interface DeleteAffiliatePayoutRes {
  data: {
    status: string;
  };
}

export async function deleteAffiliatePayout(
  data: DeleteAffiliatePayoutReq
): Promise<DeleteAffiliatePayoutRes> {
  return axios.post("affiliate-accounts/delete-payout.php", data);
}

/*
 * Affiliate Endpoint:
 * Update Affiliate Account
 */

interface UpdateAffiliateAccountReq {
  token: string;
  affiliate_id: number;
  full_name: string;
  email: string;
  phone_number: string;
  address: string;
  bank_name: string;
  bank_branch_code: string;
  bank_account_holder: string;
  bank_account_number: string;
}

interface UpdateAffiliateAccountRes {
  data: {
    status: string;
  };
}

export async function updateAffiliateAccount(
  data: UpdateAffiliateAccountReq
): Promise<UpdateAffiliateAccountRes> {
  return axios.post("affiliate-accounts/update-affiliate-account.php", data);
}

/*
 * Affiliate Endpoint:
 * Delete Affiliate Account
 */

interface DeleteAffiliateAccountReq {
  token: string;
  affiliate_id: number;
}

interface DeleteAffiliateAccountRes {
  data: {
    status: string;
  };
}

export async function deleteAffiliateAccount(
  data: DeleteAffiliateAccountReq
): Promise<DeleteAffiliateAccountRes> {
  return axios.post("affiliate-accounts/delete-affiliate-account.php", data);
}
