import axios from "./axios";

import { IAdminAccount, ISettings } from "../interfaces";

/*
 * Admin Endpoint:
 * Fetch Admin Accounts
 */

interface FetchAdminAccountsReq {
  token: string;
}

interface FetchAdminAccountsRes {
  data: {
    status: string;
    admin_accounts: IAdminAccount[];
  };
}

export async function fetchAdminAccounts(
  data: FetchAdminAccountsReq
): Promise<FetchAdminAccountsRes> {
  return axios.post("admins/fetch-admin-accounts.php", data);
}

/*
 * Admin Endpoint:
 * Create Admin Account
 */

interface CreateAdminAccountReq {
  token: string;
  admin_full_name: string;
  email: string;
  password: string;
}

interface CreateAdminAccountRes {
  data: {
    status: string;
    errorMessage?: string;
  };
}

export async function createAdminAccount(
  data: CreateAdminAccountReq
): Promise<CreateAdminAccountRes> {
  return axios.post("admins/create-admin-account.php", data);
}

/*
 * Admin Endpoint:
 * Update Admin Account
 */

interface UpdateAdminAccountReq {
  token: string;
  admin_id: number;
  admin_full_name: string;
  email: string;
  password: string;
}

interface UpdateAdminAccountRes {
  data: {
    status: string;
    errorMessage?: string;
  };
}

export async function updateAdminAccount(
  data: UpdateAdminAccountReq
): Promise<UpdateAdminAccountRes> {
  return axios.post("admins/update-admin-account.php", data);
}

/*
 * Admin Endpoint:
 * Delete Admin Accounts
 */

interface DeleteAdminAccountReq {
  token: string;
  admin_id: number;
}

interface DeleteAdminAccountRes {
  data: {
    status: string;
  };
}

export async function deleteAdminAccount(
  data: DeleteAdminAccountReq
): Promise<DeleteAdminAccountRes> {
  return axios.post("admins/delete-admin-account.php", data);
}

/*
 * Admin Endpoint:
 * Fetch Settings
 */

interface FetchSettingsReq {
  token: string;
}

interface FetchSettingsRes {
  data: {
    status: string;
    settings: ISettings;
  };
}

export async function fetchSettings(
  data: FetchSettingsReq
): Promise<FetchSettingsRes> {
  return axios.post("admins/fetch-settings.php", data);
}

/*
 * Admin Endpoint:
 * Update Settings
 */

interface UpdateSettingsReq {
  token: string;
  payfast_id: string;
  payfast_key: string;
  subscription_amount: number;
}

interface UpdateSettingsRes {
  data: {
    status: string;
    errorMessage?: string;
  };
}

export async function updateSettings(
  data: UpdateSettingsReq
): Promise<UpdateSettingsRes> {
  return axios.post("admins/update-settings.php", data);
}
