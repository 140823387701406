import axios from "./axios";

/*
 * Authentication Endpoint:
 * Admin Login
 */

interface AdminLoginReq {
  email: string;
  password: string;
}

interface AdminLoginRes {
  data: {
    status: "success" | "error";
    token: string;
    errorMessage?: string;
  };
}

export async function login(data: AdminLoginReq): Promise<AdminLoginRes> {
  return axios.post("authentication/admin-login.php", data);
}

/*
 * Authentication Endpoint:
 * Admin Reset Password - Send Email
 */

interface ForgotPasswordEmailReq {
  email: string;
}

interface ForgotPasswordEmailRes {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function forgotPasswordEmail(
  data: ForgotPasswordEmailReq
): Promise<ForgotPasswordEmailRes> {
  return axios.post("authentication/admin-reset-password-email.php", data);
}

/*
 * Authentication Endpoint:
 * Admin Reset Password - Verify OTP
 */

interface ForgotPasswordOTPReq {
  email: string;
  one_time_pin: string;
}

interface ForgotPasswordOTPRes {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function forgotPasswordOTP(
  data: ForgotPasswordOTPReq
): Promise<ForgotPasswordOTPRes> {
  return axios.post("authentication/admin-reset-password-otp.php", data);
}

/*
 * Authentication Endpoint:
 * Admin Reset Password - Update Password
 */

interface UpdatePasswordReq {
  email: string;
  one_time_pin: string;
  new_password: string;
}

interface UpdatePasswordRes {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function updatePassword(
  data: UpdatePasswordReq
): Promise<UpdatePasswordRes> {
  return axios.post("authentication/admin-reset-password.php", data);
}

/*
 * Authentication Endpoint:
 * Fetch Admin Account
 */

interface FetchUserReq {
  token: string;
}

interface FetchUserRes {
  data: {
    status: "success" | "error";
    admin_account: {
      admin_id: number;
      admin_full_name: string;
      email: string;
    };
    errorMessage?: string;
  };
}

export async function fetchUser(data: FetchUserReq): Promise<FetchUserRes> {
  return axios.post("authentication/fetch-admin.php", data);
}
