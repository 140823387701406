import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IAdminAccount } from "../../interfaces";

import UserContext from "../../contexts/UserContext";

import {
  Stack,
  Avatar,
  Button,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Center,
  Icon,
  VStack,
  Spinner,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { MdMenu, MdAddCircle, MdAdminPanelSettings } from "react-icons/md";

import MainLayout from "../../components/Layout/MainLayout";
import DeleteAdminModal from "../../components/Modals/DeleteAdminModal";

import { fetchAdminAccounts } from "../../api/adminEndpoints";

interface AdminManagerPageProps {}

const AdminManagerPage: FC<AdminManagerPageProps> = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { token } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [adminAccounts, setAdminAccounts] = useState<IAdminAccount[]>([]);
  const [accountToDelete, setAccountToDelete] = useState<IAdminAccount>();

  useEffect(() => {
    fetchAdminAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setAdminAccounts(response.data.admin_accounts);
        }

        const interval = setInterval(() => {
          setLoading(false);
        }, 1000);
        return () => clearInterval(interval);
      })
      .catch(console.log);
  }, [token, isOpen]);

  return (
    <MainLayout>
      <Stack
        w={"full"}
        mb={"20"}
        justify={"space-between"}
        align={{ base: "start", md: "center" }}
        direction={{ base: "column", md: "row" }}
      >
        <Heading>Admin Manager</Heading>

        <HStack spacing={"8"}>
          <Button
            colorScheme="black"
            variant="link"
            onClick={() => {
              navigate("/settings");
            }}
          >
            PayFast Settings
          </Button>

          <Button
            leftIcon={<MdAddCircle />}
            colorScheme="yellow"
            variant="solid"
            onClick={() => {
              navigate("/admins/create");
            }}
          >
            New Admin Account
          </Button>
        </HStack>
      </Stack>

      {loading ? (
        <Center w={"full"} h={"75%"}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="dark"
            size="xl"
          />
        </Center>
      ) : adminAccounts.length > 0 ? (
        <TableContainer bg={"white"} rounded={"8"}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Full Name</Th>
                <Th>Email</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {adminAccounts.map((account) => {
                return (
                  <Tr key={account.admin_id}>
                    <Td>
                      <Avatar
                        name={account.admin_full_name}
                        src="#"
                        bg="gray.700"
                        color={"white"}
                      />
                    </Td>
                    <Td>{account.admin_full_name}</Td>
                    <Td>{account.email}</Td>
                    <Td onClick={(e) => e.stopPropagation()}>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<MdMenu />}
                          variant="outline"
                        />
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              navigate(`/admins/${account.admin_id}/update`);
                            }}
                          >
                            Edit Account
                          </MenuItem>

                          <MenuDivider />

                          <MenuItem
                            color={"red"}
                            onClick={() => {
                              setAccountToDelete(account);
                              onOpen();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Center w={"full"} h={"75%"}>
          <VStack>
            <Icon
              as={MdAdminPanelSettings}
              w={"20"}
              h={"20"}
              color="blackAlpha.800"
            />
            <Text fontSize={"lg"}>No Admin Accounts Found</Text>
          </VStack>
        </Center>
      )}

      <DeleteAdminModal
        isOpen={isOpen}
        onClose={onClose}
        accountToDelete={accountToDelete}
      />
    </MainLayout>
  );
};

export default AdminManagerPage;
