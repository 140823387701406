import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import UserContext from "../../contexts/UserContext";

import {
  Stack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Center,
  Icon,
  VStack,
  useDisclosure,
  Spinner,
  HStack,
  FormControl,
  Input,
  Box,
} from "@chakra-ui/react";
import { MdMenu, MdSupervisorAccount } from "react-icons/md";

import MainLayout from "../../components/Layout/MainLayout";
import DeleteAffiliateModal from "../../components/Modals/DeleteAffiliateModal";
import { fetchAffiliateAccounts } from "../../api/affiliateEndpoints";
import { IAffiliateAccount } from "../../interfaces";

interface AffiliateManagerPageProps {}

const AffiliateManagerPage: FC<AffiliateManagerPageProps> = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { token } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [affiliates, setAffiliates] = useState<IAffiliateAccount[]>([]);

  const [search, setSearch] = useState("");

  const [accountToDelete, setAccountToDelete] = useState<IAffiliateAccount>();

  useEffect(() => {
    fetchAffiliateAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setAffiliates(response.data.affiliate_accounts);
        }

        const interval = setInterval(() => {
          setLoading(false);
        }, 1000);
        return () => clearInterval(interval);
      })
      .catch(console.log);
  }, [token, isOpen]);

  const handleFilterSearch = (account: IAffiliateAccount) => {
    if (search === "") {
      return true;
    }

    if (account.full_name.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }

    if (account.email.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }

    if (account.referral_key.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }

    return false;
  };

  return (
    <MainLayout>
      <Stack
        w={"full"}
        mb={"20"}
        justify={"space-between"}
        align={{ base: "start", md: "center" }}
        direction={{ base: "column", md: "row" }}
      >
        <Heading>Affiliate Account Manager</Heading>
      </Stack>

      {loading ? (
        <Center w={"full"} h={"75%"}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="black"
            size="xl"
          />
        </Center>
      ) : (
        <Box>
          {affiliates.length > 0 ? (
            <Box>
              <HStack mb={"5"} justify={"space-between"} align={"end"}>
                <HStack
                  justify={"space-between"}
                  align={"center"}
                  spacing={"8"}
                >
                  <FormControl w={"275px"}>
                    <Input
                      placeholder="Search..."
                      size="md"
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </FormControl>
                </HStack>
              </HStack>

              <TableContainer bg={"white"} rounded={"8"}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Full Name</Th>
                      <Th>Email</Th>
                      <Th>Referral Key</Th>
                      <Th>Linked Businesses</Th>
                      <Th>Payout Due</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {affiliates.filter(handleFilterSearch).map((account) => {
                      return (
                        <Tr
                          _hover={{ cursor: "pointer", bg: "gray.100" }}
                          onClick={() =>
                            navigate(`/affiliates/${account.affiliate_id}/view`)
                          }
                        >
                          <Td>{account.full_name}</Td>
                          <Td>{account.email}</Td>
                          <Td>{account.referral_key}</Td>
                          <Td>{account.linked_businesses}</Td>
                          <Td>
                            R
                            {parseFloat(
                              account.payout_due as unknown as string
                            ).toFixed(2)}
                          </Td>
                          <Td>{account.status}</Td>
                          <Td onClick={(e) => e.stopPropagation()}>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                icon={<MdMenu />}
                                variant="outline"
                              />
                              <MenuList>
                                <MenuItem
                                  onClick={() =>
                                    navigate(
                                      `/affiliates/${account.affiliate_id}/view`
                                    )
                                  }
                                >
                                  Open
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    navigate(
                                      `/affiliates/${account.affiliate_id}/update`
                                    )
                                  }
                                >
                                  Edit Account
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                  color={"red"}
                                  onClick={() => {
                                    setAccountToDelete(account);
                                    onOpen();
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Center w={"full"} h={"75%"}>
              <VStack>
                <Icon
                  as={MdSupervisorAccount}
                  w={"20"}
                  h={"20"}
                  color="blackAlpha.800"
                />
                <Text fontSize={"lg"}>No Affiliate Accounts Found</Text>
              </VStack>
            </Center>
          )}
        </Box>
      )}

      <DeleteAffiliateModal
        isOpen={isOpen}
        onClose={onClose}
        accountToDelete={accountToDelete}
      />
    </MainLayout>
  );
};

export default AffiliateManagerPage;
