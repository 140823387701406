import axios from "./axios";

import { IPayment } from "../interfaces";

/*
 * Payment Endpoint:
 * Fetch Payments
 */

interface FetchPaymentsReq {
  token: string;
}

interface FetchPaymentsRes {
  data: {
    status: string;
    payments: IPayment[];
  };
}

export async function fetchPayments(
  data: FetchPaymentsReq
): Promise<FetchPaymentsRes> {
  return axios.post("payments/fetch-payments.php", data);
}

/*
 * Payment Endpoint:
 * Fetch Business Payments
 */

interface FetchBusinessPaymentsReq {
  token: string;
  account_id: number;
}

interface FetchBusinessPaymentsRes {
  data: {
    status: string;
    payments: {
      payment_id: number;
      payment_amount: number;
      date_created: string;
    }[];
  };
}

export async function fetchBusinessPayments(
  data: FetchBusinessPaymentsReq
): Promise<FetchBusinessPaymentsRes> {
  return axios.post("payments/fetch-business-payments.php", data);
}
