import { FC } from "react";

import { Center, Spinner } from "@chakra-ui/react";

const InitialLoader: FC = () => {
  return (
    <Center w={"100vw"} h={"100vh"}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="dark"
        size="xl"
      />
    </Center>
  );
};

export default InitialLoader;
