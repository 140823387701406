import { FC, ReactNode } from "react";

import { Box, HStack } from "@chakra-ui/react";

import NavigationBar from "./NavigationBar";

interface MainLayoutProps {
  children: ReactNode | ReactNode[];
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <HStack
      w={"100vw"}
      h={"100vh"}
      justify={"space-between"}
      align={"stretch"}
      spacing={"0"}
    >
      <NavigationBar />

      <Box
        w={"full"}
        p={{ base: "12px", md: "32px" }}
        bgColor={"blackAlpha.200"}
        overflowY={"scroll"}
      >
        {children}
      </Box>
    </HStack>
  );
};

export default MainLayout;
