/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import { ISettings } from "../../interfaces";

import UserContext from "../../contexts/UserContext";

import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";
import { FaArrowCircleLeft } from "react-icons/fa";

import MainLayout from "../../components/Layout/MainLayout";

import { fetchSettings, updateSettings } from "../../api/adminEndpoints";

interface SettingsPageProps {}

interface IFormInputs {
  payfast_id: string;
  payfast_key: string;
  subscription_amount: number;
}

const SettingsPage: FC<SettingsPageProps> = () => {
  const navigate = useNavigate();
  let toast = useToast();

  const { token } = useContext(UserContext);

  const [settings, setSettings] = useState<ISettings>();

  const { register, handleSubmit, reset } = useForm<IFormInputs>({
    reValidateMode: "onChange",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSettings({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setSettings(response.data.settings);
          reset({
            payfast_id: response.data.settings.payfast_id,
            payfast_key: response.data.settings.payfast_key,
            subscription_amount: response.data.settings.subscription_amount,
          });
        } else {
          navigate("/admins");
        }
      })
      .catch(console.log);
  }, [token]);

  const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
    toast.closeAll();
    const loadingInterval = setInterval(() => {
      setLoading(true);
    }, 500);

    updateSettings({
      token: token,
      ...formData,
    })
      .then((response) => {
        if (response.data.status === "success") {
          navigate("/admins");

          toast({
            title: "Settings have been updated!",
            position: "bottom-right",
            status: "success",
            isClosable: true,
          });
        } else {
          toast({
            title: "Edit Settings Error Error:",
            description: response.data.errorMessage,
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        }

        setLoading(false);
        clearInterval(loadingInterval);
      })
      .catch(console.log);
  };

  return (
    <MainLayout>
      <Heading mb={"4"}>Edit PayFast Settings</Heading>

      <Button
        variant={"link"}
        colorScheme="black"
        leftIcon={<FaArrowCircleLeft />}
        onClick={() => {
          navigate("/admins");
        }}
      >
        back to Admin Manager
      </Button>

      <Center mt={"10"}>
        <Box
          w={{ base: "full", md: "450px" }}
          p={"8"}
          bg={"white"}
          shadow={"lg"}
          rounded={"8"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={"5"}>
              <FormControl>
                <FormLabel>PayFast Merchant ID</FormLabel>
                <Input
                  type="text"
                  {...register("payfast_id", {
                    required: "PayFast Merchant ID is required",
                  })}
                  defaultValue={settings?.payfast_id}
                />
              </FormControl>

              <FormControl>
                <FormLabel>PayFast Merchant Key</FormLabel>
                <Input
                  type="text"
                  {...register("payfast_key", {
                    required: "PayFast Mercahnt Key is required",
                  })}
                  defaultValue={settings?.payfast_key}
                />
              </FormControl>

              <Divider />

              <Alert status="warning">
                <AlertIcon />
                Changing these settings will not effect currently subscribed
                users.
              </Alert>

              <FormControl>
                <FormLabel>Monthly Subscription Amount (ZAR)</FormLabel>
                <Input
                  type="number"
                  {...register("subscription_amount", {
                    required: "Subscription Amount is required",
                  })}
                  defaultValue={settings?.subscription_amount}
                />
              </FormControl>
            </VStack>

            <Box mt={"10"} textAlign={"right"}>
              <Button
                colorScheme="yellow"
                mr={3}
                type={"submit"}
                isLoading={loading}
              >
                Save Changes
              </Button>
              <Button
                onClick={() => {
                  navigate("/admins");
                }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Center>
    </MainLayout>
  );
};

export default SettingsPage;
