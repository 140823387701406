import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import UserContext from "../../contexts/UserContext";

import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FaArrowCircleLeft } from "react-icons/fa";

import MainLayout from "../../components/Layout/MainLayout";

import { createAdminAccount } from "../../api/adminEndpoints";

interface CreateAdminPageProps {}

interface IFormInputs {
  admin_full_name: string;
  email: string;
  password: string;
  confirm_password: string;
  phone_number: string;
}

const CreateAdminPage: FC<CreateAdminPageProps> = () => {
  const navigate = useNavigate();
  let toast = useToast();

  const { token } = useContext(UserContext);

  const { register, handleSubmit } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
    toast.closeAll();
    const loadingInterval = setInterval(() => {
      setLoading(true);
    }, 500);

    if (formData.password === formData.confirm_password) {
      createAdminAccount({ token: token, ...formData })
        .then((response) => {
          if (response.data.status === "success") {
            navigate("/admins");

            toast({
              title: "Admin Account has been created!",
              position: "bottom-right",
              status: "success",
              isClosable: true,
            });
          } else {
            toast({
              title: "New Admin Account Error:",
              description: response.data.errorMessage,
              position: "top-right",
              status: "error",
              isClosable: true,
            });
          }

          setLoading(false);
          clearInterval(loadingInterval);
        })
        .catch(console.log);
    } else {
      toast({
        title: "New Admin Account Error:",
        description: "Passwords do not match.",
        position: "top-right",
        status: "error",
        isClosable: true,
      });

      setLoading(false);
      clearInterval(loadingInterval);
    }
  };

  return (
    <MainLayout>
      <Heading mb={"4"}>New Admin Account</Heading>

      <Button
        variant={"link"}
        colorScheme="black"
        leftIcon={<FaArrowCircleLeft />}
        onClick={() => {
          navigate("/admins");
        }}
      >
        back to Admin Manager
      </Button>

      <Center mt={"10"}>
        <Box
          w={{ base: "full", md: "450px" }}
          p={"8"}
          bg={"white"}
          shadow={"lg"}
          rounded={"8"}
        >
          <Text mb={"8"}>
            Please provide the account information for the new Administrative
            User:
          </Text>

          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={"5"}>
              <FormControl>
                <FormLabel>Full Name</FormLabel>
                <Input
                  type="text"
                  {...register("admin_full_name", {
                    required: "Full Name is required",
                  })}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  {...register("email", { required: "Email is required" })}
                />
              </FormControl>

              <Divider />

              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Confirm Password</FormLabel>
                <Input
                  type="password"
                  {...register("confirm_password", {
                    required: "Confirm Password is required",
                  })}
                />
              </FormControl>
            </VStack>

            <Box mt={"10"} textAlign={"right"}>
              <Button
                colorScheme="yellow"
                mr={3}
                type={"submit"}
                isLoading={loading}
              >
                Create Admin Account
              </Button>
              <Button
                onClick={() => {
                  navigate("/admins");
                }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Center>
    </MainLayout>
  );
};

export default CreateAdminPage;
