import { IAffiliateAccount, IBusinessAccount } from "../interfaces";
import axios from "./axios";

/*
 * Dashboard Endpoint:
 * Fetch Overview
 */

interface FetchOverviewReq {
  token: string;
}

interface FetchOverviewRes {
  data: {
    status: string;
    overview: {
      business_count: number;
      affiliate_count: number;
      search_count: number;
      earning_total: string;
      earning_month: string;
      payout_due: string;
    };
  };
}

export async function fetchOverview(
  data: FetchOverviewReq
): Promise<FetchOverviewRes> {
  return axios.post("dashboard/fetch-overview.php", data);
}

/*
 * Dashboard Endpoint:
 * Fetch Unseen Businesses
 */

interface FetchNewBusinessAccountsReq {
  token: string;
}

interface FetchNewBusinessAccountsRes {
  data: {
    status: string;
    business_accounts: IBusinessAccount[];
  };
}

export async function fetchNewBusinessAccounts(
  data: FetchNewBusinessAccountsReq
): Promise<FetchNewBusinessAccountsRes> {
  return axios.post("dashboard/fetch-unseen-business-accounts.php", data);
}

/*
 * Dashboard Endpoint:
 * Fetch Unseen Affiliates
 */

interface FetchNewAffiliateAccountsReq {
  token: string;
}

interface FetchNewAffiliateAccountsRes {
  data: {
    status: string;
    affiliate_accounts: IAffiliateAccount[];
  };
}

export async function fetchNewAffiliateAccounts(
  data: FetchNewAffiliateAccountsReq
): Promise<FetchNewAffiliateAccountsRes> {
  return axios.post("dashboard/fetch-unseen-affiliate-accounts.php", data);
}

/*
 * Dashboard Endpoint:
 * Mark Business As Seen
 */

interface MarkBusinessAsSeenReq {
  token: string;
  account_id: number;
}

interface MarkBusinessAsSeenRes {
  data: {
    status: string;
  };
}

export async function markBusinessAsSeen(
  data: MarkBusinessAsSeenReq
): Promise<MarkBusinessAsSeenRes> {
  return axios.post("dashboard/mark-business-as-seen.php", data);
}

/*
 * Dashboard Endpoint:
 * Mark Affiliate As Seen
 */

interface MarkAffiliateAsSeenReq {
  token: string;
  affiliate_id: number;
}

interface MarkAffiliateAsSeenRes {
  data: {
    status: string;
  };
}

export async function markAffiliateAsSeen(
  data: MarkAffiliateAsSeenReq
): Promise<MarkAffiliateAsSeenRes> {
  return axios.post("dashboard/mark-affiliate-as-seen.php", data);
}
