import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import UserContext from "../../contexts/UserContext";

import {
  Box,
  VStack,
  HStack,
  Center,
  Heading,
  FormLabel,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  Image,
  useToast,
} from "@chakra-ui/react";
import { FaArrowCircleRight } from "react-icons/fa";

import { login } from "../../api/authenticationEndpoint";

interface IFormInputs {
  email: string;
  password: string;
}

const LoginPage: FC = () => {
  let navigate = useNavigate();
  let toast = useToast();

  const { setToken } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
    toast.closeAll();
    const loadingInterval = setInterval(() => {
      setLoading(true);
    }, 500);

    login(formData)
      .then((response) => {
        if (response.data.status === "success") {
          setToken(response.data.token);
          localStorage.setItem("token", response.data.token);
        } else {
          toast({
            title: "Login Error:",
            description: response.data.errorMessage,
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        }

        setLoading(false);
        clearInterval(loadingInterval);
      })
      .catch(console.log);
  };

  return (
    <Center w={"100vw"} h={"100vh"}>
      <VStack>
        <Box w={"250px"} maxW={"100vw"}>
          <Image src="/media/the_hive_logo_b.png" alt="The Hive Logo" />
        </Box>

        <Box w={"400px"} maxW={"92vw"} p={"8"} rounded={"8"} shadow={"lg"}>
          <Heading size="lg">Admin Login</Heading>

          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack mt={"8"} spacing={"4"}>
              <FormControl isInvalid={errors.email ? true : false}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  {...register("email", { required: "Email is required" })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.password ? true : false}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
            </VStack>

            <HStack mt={"12"} justify={"space-between"} align={"center"}>
              <Button
                variant={"link"}
                colorScheme="black"
                size={"sm"}
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password
              </Button>

              <Button
                colorScheme="yellow"
                rightIcon={<FaArrowCircleRight />}
                type={"submit"}
                isLoading={loading}
              >
                Login
              </Button>
            </HStack>
          </form>
        </Box>
      </VStack>
    </Center>
  );
};

export default LoginPage;
