import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";

import UserContext from "../../contexts/UserContext";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from "@chakra-ui/react";

import { deleteAdminAccount } from "../../api/adminEndpoints";
import { IPayout } from "../../interfaces";
import { deleteAffiliatePayout } from "../../api/affiliateEndpoints";

interface DeletePayoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  payout?: IPayout;
  refreshPayouts: () => void;
}

const DeletePayoutModal: FC<DeletePayoutModalProps> = ({
  isOpen,
  onClose,
  payout,
  refreshPayouts,
}) => {
  let toast = useToast();

  const { token } = useContext(UserContext);

  const handleDelete = () => {
    deleteAffiliatePayout({
      token: token,
      affiliate_id: payout?.affiliate_id || 0,
      amount: payout?.amount || 0,
      payout_id: payout?.payout_id || 0,
    })
      .then((response) => {
        if (response.data.status === "success") {
          refreshPayouts();
          onClose();

          toast({
            title: "Payout has been deleted!",
            position: "bottom-right",
            status: "success",
            isClosable: true,
          });
        }
      })
      .catch(console.log);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "full", md: "md" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Payout</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text>
            Are you sure you want to delete a payout for R{payout?.amount}?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeletePayoutModal;
